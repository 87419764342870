export const MenuItems = [
    {
        title: "Home",
        url: "/home",
        cName: "nav-links"
    },
    {
        title: "Sign In",
        url: "SignIn",
        cName: "nav-links-mobile"
    }
]