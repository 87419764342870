import React from "react";
import { Link } from 'react-router-dom';

function GameSubNav(props){

    return(
        <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900">
                    <Link to={props.back}><i className="fa fa-arrow-left mx-5" aria-hidden="true"></i></Link>  {props.name}
                </h1>
            </div>
        </header>
    );
}


export default GameSubNav;